import React from 'react';
import {industriesBenefits, industriesAbout, industriesQuestions} from '../../data';
import IndustriesHeadSection from './components/IndustriesHeadSection';
import IndustriesWrapperSection from './components/IndustriesWrapperSection';
import IndustriesBenefitsSection from './components/IndustriesBenefitsSection';
import IndustriesAboutSection from './components/IndustriesAboutSection';
import IndustriesQuestionSection from './components/IndustriesQuestionSection';
import IndustriesStartProjectSection from './components/IndustriesStartProjectSection';
import '../../assets/css/industries.scss';

const Industries = () => {
    return (
        <>
            <IndustriesHeadSection/>

            <IndustriesWrapperSection/>

            <IndustriesBenefitsSection benefits={industriesBenefits}/>

            <IndustriesAboutSection about={industriesAbout}/>

            <IndustriesQuestionSection questions={industriesQuestions}/>

            <IndustriesStartProjectSection/>
        </>
    );
};

export default Industries;