import React from 'react';
import {Link} from 'react-router-dom';
import {Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import FadeInUp from '../../../components/FadeInUp';

const HomeHeadSection = () => {
    return (
        <section className="home-head">
            <div className="container">
                <FadeInUp className="home-head-wrapper">
                    <div className="home-head-left">
                        <h1 className="title">Unlock Your Online Business Potential</h1>
                        <p>
                            We are Your premier destination for top-notch ecommerce website development on the
                            Shopify platform.
                        </p>

                        <div className="home-head-buttons">
                            <Link to={'/process'} className="btn btn-primary btn-border btn-large">
                                Start a Project
                            </Link>

                            <Link to={'/our-works'} className="btn btn-link">
                                <img src="/images/arrow-right-green.svg" width={18} height={18} alt=""/>
                                Discover our Works
                            </Link>
                        </div>
                    </div>
                    <div className="home-head-right">
                        <img src="/images/home-head-img.png" width={495} height={531} alt=""/>
                    </div>
                </FadeInUp>
            </div>

            <div className="home-head-trusted">
                <FadeInUp className="container">
                    <p>Trusted by</p>

                    <Swiper
                        modules={[Autoplay]}
                        spaceBetween={24}
                        slidesPerView={2}
                        loop={true}
                        autoplay={{
                            delay: 1500,
                            disableOnInteraction: false
                        }}
                        breakpoints={{
                            1199: {
                                slidesPerView: 6,
                            },
                            991: {
                                slidesPerView: 5,
                            },
                            768: {
                                slidesPerView: 4,
                            },
                            576: {
                                slidesPerView: 3,
                            }
                        }}
                    >
                        {Array.from(Array(10).keys()).map((_, index) =>
                            <SwiperSlide key={index}>
                                <img src={`/images/logo-ipsum-${index + 1}.svg`} alt="Logo ipsum"/>
                            </SwiperSlide>
                        )}
                    </Swiper>
                </FadeInUp>
            </div>
        </section>
    );
};

export default HomeHeadSection;