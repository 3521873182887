import React from 'react';
import FadeInUp from '../../../components/FadeInUp';


const IndustriesBenefitsSection = ({benefits}) => {
    return (
        <section className="container">
            <div className="industries-benefits">
                <FadeInUp className="industries-benefits-head">
                    <h2 className="title">
                        Unlock the Power of Shopify for Your Fashion Brand with Our Exclusive Benefits
                    </h2>
                    <p className="text-secondary mb-0">
                        Elevate your fashion brand with our tailored Shopify solutions. From stunning visuals and
                        mobile optimization to fashion-focused SEO and secure payments, we provide the essential
                        benefits your fashion business needs.
                    </p>
                </FadeInUp>

                <div className="industries-benefits-wrapper">
                    {benefits.map(benefit =>
                        <FadeInUp className="industries-benefits-item" key={benefit.id}>
                            <img
                                src={`/images/industries-benefits-icon-${benefit.id}.svg`}
                                alt={benefit.title}
                                height={40}
                                width={40}
                            />
                            <strong>{benefit.title}</strong>
                            <p className="text-secondary mb-0">{benefit.text}</p>
                        </FadeInUp>
                    )}
                </div>
            </div>
        </section>
    );
};

export default IndustriesBenefitsSection;