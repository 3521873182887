import React from 'react';
import FadeInUp from '../../../components/FadeInUp';

const IndustriesWrapperSection = () => {
    return (
        <section className="industries-wrapper">
            <div className="container">
                <FadeInUp className="industries-item">
                    <div className="industries-item-content">
                        <h2 className="title">Why choose us for the Fashion Industry.</h2>
                        <p className="text-secondary mb-0">
                            Our visually stunning designs, mobile optimization, and effective content strategies
                            ensure an exceptional user experience. Trust us to boost your fashion e-commerce
                            success, backed by a track record of client satisfaction and ongoing support.
                        </p>
                    </div>
                    <div className="industries-item-img">
                        <img src="/images/industries-item-1.png" width={540} height={380} alt=""/>
                    </div>
                </FadeInUp>
                <FadeInUp className="industries-item">
                    <div className="industries-item-img">
                        <img src="/images/industries-item-2.png" width={540} height={380} alt=""/>
                    </div>
                    <div className="industries-item-content">
                        <h2 className="title">Shopify Apps and Integrations</h2>
                        <p className="text-secondary mb-0">
                            Our visually stunning designs, mobile optimization, and effective content strategies
                            ensure an exceptional user experience. Trust us to boost your fashion e-commerce
                            success, backed by a track record of client satisfaction and ongoing support.
                        </p>
                    </div>
                </FadeInUp>
                <FadeInUp className="industries-item">
                    <div className="industries-item-content">
                        <h2 className="title">Better Integration</h2>
                        <p className="text-secondary mb-0">
                            Our visually stunning designs, mobile optimization, and effective content strategies
                            ensure an exceptional user experience. Trust us to boost your fashion e-commerce
                            success, backed by a track record of client satisfaction and ongoing support.
                        </p>
                    </div>
                    <div className="industries-item-img">
                        <img src="/images/industries-item-3.png" width={540} height={380} alt=""/>
                    </div>
                </FadeInUp>
            </div>
        </section>
    );
};

export default IndustriesWrapperSection;