import React, {useMemo, useState} from 'react';
import {ourWorksFilters, ourWorksItems} from '../../data';
import OurWorksItem from '../../components/OurWorksItem';
import {Link, useNavigate} from 'react-router-dom';
import FadeInUp from '../../components/FadeInUp';
import '../../assets/css/our-works.scss';

const OurWorks = () => {
    const [selectedCategory, setSelectedCategory] = useState('all');
    const [ourWorkItemClasses, setOurWorkItemClasses] = useState('our-works-item');
    const navigate = useNavigate();
    let filteredAllTimeout;
    let filteredOtherTimeout;

    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
    };

    const filteredCategory = useMemo(() => {
        if (selectedCategory === 'all') {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            filteredAllTimeout = setTimeout(() => {
                setOurWorkItemClasses('our-works-item');
            }, 1000);

            clearTimeout(filteredAllTimeout);

            setOurWorkItemClasses('our-works-item animated2');

            return ourWorksItems;
        } else {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            filteredOtherTimeout = setTimeout(() => {
                setOurWorkItemClasses('our-works-item');
            }, 1000);

            clearTimeout(filteredOtherTimeout);

            setOurWorkItemClasses('our-works-item animated');

            return ourWorksItems.filter(item => (
                item.category === selectedCategory
            ));
        }
    }, [selectedCategory]);

    const getActiveFilter = (category) => {
        return selectedCategory === category ? 'our-works-filters-item active' : 'our-works-filters-item'
    }

    return (
        <>
            <section className="our-works-head">
                <FadeInUp className="container">
                    <div className="our-works-head-wrapper">
                        <h1 className="title">Our Work</h1>
                        <p className="text-secondary mb-0">
                            Explore our portfolio of stunning Shopify websites, where we turn digital dreams into
                            thriving online realities. Each project is a testament to our commitment to innovation,
                            design excellence, and e-commerce success.
                        </p>
                    </div>
                </FadeInUp>
            </section>

            <div className="container">
                <FadeInUp className="our-works-filters">
                    {ourWorksFilters.map(filter =>
                        <button
                            onClick={() => handleCategoryChange(filter.category)}
                            className={getActiveFilter(filter.category)}
                            type="button"
                            key={filter.id}
                        >
                            {filter.text}
                        </button>
                    )}
                </FadeInUp>

                <FadeInUp className="our-works-wrapper">
                    {filteredCategory.map(ourWorkItem =>
                        <OurWorksItem
                            onClick={() => navigate('/our-works/individual')}
                            image={`/images/latest-project-${ourWorkItem.id}.png`}
                            description={ourWorkItem.description}
                            className={ourWorkItemClasses}
                            title={ourWorkItem.title}
                            key={ourWorkItem.id}
                        />
                    )}
                </FadeInUp>

                <FadeInUp className="our-works-wrapper-button">
                    <Link to={'/contact-us'} className="btn btn-large btn-primary">Contact us</Link>
                </FadeInUp>
            </div>
        </>
    );
};

export default OurWorks;