import React from 'react';
import FadeInUp from '../../../components/FadeInUp';

const IndustriesAboutSection = ({about}) => {
    return (
        <section className="industries-about">
            <div className="container">
                <div className="industries-about-wrapper">
                    <FadeInUp className="industries-about-left">
                        <h2 className="title">Don’t stress about starting from scratch</h2>
                        <p>
                            Discover the ease and convenience of Shopify storefronts, where you can create your
                            dream online store without any hassle. With a user-friendly interface and a wide range
                            of customizable templates, you'll have everything you need to begin selling your
                            products in no time.
                        </p>
                    </FadeInUp>
                    <div className="industries-about-right">
                        <ul className="ul-check">
                            {about.map(industriesAboutItem =>
                                <li key={industriesAboutItem.id}>
                                    <FadeInUp>
                                        {industriesAboutItem.text}
                                    </FadeInUp>
                                </li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default IndustriesAboutSection;