import React from 'react';
import {Link} from 'react-router-dom';
import FadeInUp from '../../../components/FadeInUp';
import SectionBackground from '../../../components/UI/section/SectionBackground';

const HomeBuildingSection = () => {
    return (
        <FadeInUp>
            <SectionBackground
                contentClassName={'home-building-content'}
            >
                <h2 className="title">Start building today</h2>
                <p>
                    Join the millions of entrepreneurs who have already embraced Shopify as their trusted
                    e-commerce partner.
                </p>

                <Link to={'/process'} className="btn btn-primary btn-border btn-large">
                    Start a Project
                </Link>
            </SectionBackground>
        </FadeInUp>
    );
};

export default HomeBuildingSection;