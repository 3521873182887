import React, {useRef} from 'react';
import FadeInUp from '../../../components/FadeInUp';
import useScrollTriggeredCountUp from '../../../hooks/useScrollTriggeredCountUp';

const HomeTestimonialsSection = ({testimonials}) => {
    const counterRef = useRef(null);
    const projectsDoneCount = useScrollTriggeredCountUp(counterRef, 24);
    const someStatesCount = useScrollTriggeredCountUp(counterRef, 16);
    const fasterCount = useScrollTriggeredCountUp(counterRef, 2);
    const clientsCount = useScrollTriggeredCountUp(counterRef, 117);

    return (
        <section className="home-testimonials">
            <div className="container">
                <FadeInUp>
                    <h2>Testimonials</h2>
                </FadeInUp>

                <div className="home-testimonials-wrapper">
                    {testimonials.map(testimonial =>
                        <FadeInUp className="home-testimonials-item" key={testimonial.id}>
                            <img
                                src="/images/testimonials.svg"
                                alt={testimonial.author}
                                width={32}
                                height={20}
                            />
                            <p>{testimonial.message}</p>
                            <span>{testimonial.author}</span>
                        </FadeInUp>
                    )}
                </div>

                <div className="home-testimonials-text">
                    <FadeInUp className="title">
                        Through our vast experience, we've discovered that businesses of all kinds can greatly
                        benefit from digital enhancements.
                    </FadeInUp>

                    <FadeInUp>
                        <p>
                            Whether it's sprucing up your website, streamlining internal operations, or refining a
                            digital product, we're here to help you every step of the way.
                        </p>
                        <p>
                            Whether it's sprucing up your website, streamlining internal operations, or refining a
                            digital product, we're here to help you every step of the way.
                        </p>
                    </FadeInUp>
                </div>

                <div className="home-testimonials-footer" ref={counterRef}>
                    <FadeInUp className="home-testimonials-footer-item">
                        <strong>{projectsDoneCount}+</strong>
                        <span>Projects done</span>
                    </FadeInUp>
                    <FadeInUp className="home-testimonials-footer-item">
                        <strong>{someStatesCount}</strong>
                        <span>Some stats</span>
                    </FadeInUp>
                    <FadeInUp className="home-testimonials-footer-item">
                        <strong>{fasterCount}x</strong>
                        <span>Faster</span>
                    </FadeInUp>
                    <FadeInUp className="home-testimonials-footer-item">
                        <strong>{clientsCount}</strong>
                        <span>Clients</span>
                    </FadeInUp>
                </div>
            </div>
        </section>
    );
};

export default HomeTestimonialsSection;