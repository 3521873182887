import React from 'react';
import FadeInUp from '../../components/FadeInUp';
import SectionBackground from '../../components/UI/section/SectionBackground';
import '../../assets/css/contact-us.scss';

const ContactUs = () => {
    return (
        <>

            <section className="contact-head">
                <div className="container">
                    <FadeInUp>
                        <h1 className="title">Get in Touch: Contact Us for Exceptional Support and Inquiries</h1>
                    </FadeInUp>
                    <img src="/images/contact-head-img.png" width={1440} height={484} alt=""/>
                </div>
            </section>

            <div className="contact-info">
                <div className="container">
                    <div className="contact-info-wrapper">
                        <FadeInUp className="contact-info-item">
                            <img src="/images/location.svg" width={40} height={40} alt=""/>
                            <h3>Spain</h3>
                            <div>
                                <span>Address:</span>
                                C/ Correos 6, 46002 Valencia, Spain
                            </div>
                            <div>
                                <span>Email:</span>
                                <a href="mailto:spain@support.com">
                                    spain@support.com
                                </a>
                            </div>
                            <div>
                                <span>Phone:</span>
                                <a href="tel:+37412345678">
                                    +34-12345678
                                </a>
                            </div>
                        </FadeInUp>

                        <FadeInUp className="contact-info-item">
                            <img src="/images/location.svg" width={40} height={40} alt=""/>
                            <h3>Netherlands</h3>
                            <div>
                            <span>Address:</span>
                                Leidsevaartweg 1, 2106NA Heemstede, The Netherlands
                            </div>
                            <div>
                                <span>Email:</span>
                                <a href="mailto:netherlands@support.com">
                                    netherlands@support.com
                                </a>
                            </div>
                            <div>
                                <span>Phone:</span>
                                <a href="tel:+31643157037">
                                    +316-43157037
                                </a>
                            </div>
                        </FadeInUp>
                    </div>
                </div>
            </div>

            <FadeInUp>
                <SectionBackground
                    contentClassName={'contact-team-inner'}
                >
                    <h2 className="title">Reach Out to Our Team</h2>
                    <div className="contact-team-contacts">
                        <a href="mailto:info@domainname.com">info@domainname.com</a>
                        <a href="mailto:support@domainname.com">support@domainname.com</a>
                    </div>
                    <p>
                        Stay connected with us on social media where we consistently share inspiring stories, and
                        exciting glimpses into our latest projects.
                    </p>

                    <div className="contact-team-social">
                        <a href="/" target="_blank" aria-label="linkedin">
                            <img src="/images/linkedin-green.svg" width={32} height={32} alt=""/>
                        </a>
                        <a href="/" target="_blank" aria-label="instagram">
                            <img src="/images/instagram-green.svg" width={32} height={32} alt=""/>
                        </a>
                        <a href="/" target="_blank" aria-label="facebook">
                            <img src="/images/facebook-green.svg" width={32} height={32} alt=""/>
                        </a>
                        <a href="/" target="_blank" aria-label="twitter">
                            <img src="/images/twitter-green.svg" width={32} height={32} alt=""/>
                        </a>
                    </div>
                </SectionBackground>
            </FadeInUp>
        </>
    );
};

export default ContactUs;