import React from 'react';
import FadeInUp from '../../../components/FadeInUp';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from 'swiper/modules';

const HomeServicesSection = ({services}) => {
    return (
        <section className="home-services">
            <FadeInUp className="home-services-container">
                <div className="home-services-wrapper">
                    <div className="home-services-left">
                        <h2 className="title">Our services</h2>
                        <p className="text-secondary mb-0">
                            Our team of designers and developers will create a unique and visually appealing theme
                            for your Shopify store, tailored to your brand identity and specifications.
                        </p>

                        <div className="home-services-buttons">
                            <div className="swiper-button-prev"/>
                            <div className="swiper-button-next"/>
                        </div>
                    </div>

                    <div className="home-services-slider">
                        <Swiper
                            modules={[Navigation]}
                            spaceBetween={16}
                            slidesPerView={1.3}
                            breakpoints={{
                                1200: {
                                    slidesPerView: 2.3,
                                    spaceBetween: 24,
                                },
                            }}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            {services.map(ourServicesItem =>
                                <SwiperSlide key={ourServicesItem.id}>
                                    <div className="home-services-item">
                                        <img
                                            src={`/images/services-icon-${ourServicesItem.id}.svg`}
                                            className="home-services-item-icon"
                                            alt={ourServicesItem.title}
                                            width={40}
                                            height={40}
                                        />
                                        <strong className="text-lg">{ourServicesItem.title}</strong>
                                        <p className="text-secondary mb-0">{ourServicesItem.homeText}</p>
                                    </div>
                                </SwiperSlide>
                            )}
                        </Swiper>
                    </div>
                </div>
            </FadeInUp>
        </section>
    );
};

export default HomeServicesSection;