import React from 'react';
import {ourWorksItems, ourServices, testimonials} from '../../data';
import HomeHeadSection from './components/HomeHeadSection';
import HomeServicesSection from './components/HomeServicesSection';
import HomeBuildingSection from './components/HomeBuildingSection';
import HomeEcommerceSection from './components/HomeEcommerceSection';
import HomeTestimonialsSection from './components/HomeTestimonialsSection';
import HomeLatestProjectsSection from './components/HomeLatestProjectsSection';
import '../../assets/css/home.scss';

const Home = () => {


    return (
        <>
            <HomeHeadSection/>

            <HomeLatestProjectsSection ourWorks={ourWorksItems}/>

            <HomeServicesSection services={ourServices}/>

            <HomeTestimonialsSection testimonials={testimonials}/>

            <HomeEcommerceSection/>

            <HomeBuildingSection/>
        </>
    );
};

export default Home;