import React from 'react';
import {Link} from 'react-router-dom';
import FadeInUp from '../../../components/FadeInUp';

const IndustriesHeadSection = () => {
    return (
        <section className="industries-head">
            <div className="container">
                <FadeInUp className="industries-head-wrapper">
                    <div className="industries-head-left">
                        <strong>Industries</strong>
                        <h1 className="title">
                            Runway-Ready Shopify Solutions for the Fashion Industry.
                        </h1>
                        <p>
                            Whether you're a boutique brand or a global fashion icon, we have the expertise to
                            enhance your online presence. Expect seamless inventory management, stunning visuals,
                            and a shopping experience that keeps your customers coming back for more.
                        </p>
                        <Link to={'/process'} className="btn btn-primary btn-border btn-large">
                            Start a Project
                        </Link>
                    </div>
                    <div className="industries-head-right">
                        <img src="/images/industries-head-img.svg" width={512} height={461} alt=""/>
                    </div>
                </FadeInUp>
            </div>
        </section>
    );
};

export default IndustriesHeadSection;