import React from 'react';
import FadeInUp from '../../../components/FadeInUp';
import OurWorksItem from '../../../components/OurWorksItem';
import {Link} from 'react-router-dom';

const HomeLatestProjectsSection = ({ourWorks}) => {
    return (
        <section className="home-latest-projects">
            <div className="container">
                <FadeInUp>
                    <h2>Latest Projects</h2>
                </FadeInUp>

                <div className="home-latest-project-wrapper">
                    {ourWorks.slice(0, 3).map(ourWorkItem =>
                        <FadeInUp key={ourWorkItem.id}>
                            <OurWorksItem
                                image={`/images/latest-project-${ourWorkItem.id}.png`}
                                className={'home-latest-projects-item'}
                                description={ourWorkItem.description}
                                title={ourWorkItem.title}
                            />
                        </FadeInUp>
                    )}
                </div>

                <FadeInUp className="text-center">
                    <Link to={'/our-works'} className="btn btn-primary">
                        All Projects
                    </Link>
                </FadeInUp>
            </div>
        </section>
    );
};

export default HomeLatestProjectsSection;