import React from 'react';
import {Link} from 'react-router-dom';
import FadeInUp from '../../../components/FadeInUp';
import SectionBackground from '../../../components/UI/section/SectionBackground';

const IndustriesStartProjectSection = () => {
    return (
        <FadeInUp>
            <SectionBackground
                contentClassName={'industries-contact-wrapper'}
            >
                <h2 className="title">Ready to elevate your fashion brand's online presence?</h2>
                <p>Contact us today and let's get started on your fashion e-commerce journey!</p>
                <Link to={'/process'} className="btn btn-primary btn-border btn-large">
                    Start a Project
                </Link>
            </SectionBackground>
        </FadeInUp>
    );
};

export default IndustriesStartProjectSection;