import React, {useState} from 'react';
import FadeInUp from '../../../components/FadeInUp';
import Collapse from '../../../components/UI/collapse/Collapse';

const IndustriesQuestionSection = ({questions}) => {
    const [selected, setSelected] = useState(null);

    const toggleCollapse = (index) => {
        if (selected === index) {
            return setSelected(null);
        }

        setSelected(index);
    }

    return (
        <section className="industries-questions">
            <div className="container">
                <FadeInUp className="industries-questions-head">
                    <h2 className="title">Got Questions? We've Got Answers!</h2>
                    <p className="text-secondary mb-0">
                        We've compiled a list of commonly asked questions to make your experience smoother and more
                        enjoyable. Whether you're curious about our services, processes, or how we can assist you,
                        you'll find the information you need right here.
                    </p>
                </FadeInUp>

                <div className="industries-questions-wrapper">
                    {questions.map(item =>
                        <FadeInUp key={item.id}>
                            <Collapse
                                id={item.id}
                                selected={selected}
                                question={item.question}
                                onClick={() => toggleCollapse(item.id)}
                            >
                                {item.answer}
                            </Collapse>
                        </FadeInUp>
                    )}
                </div>
            </div>
        </section>
    );
};

export default IndustriesQuestionSection;