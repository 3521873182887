import React from 'react';
import FadeInUp from '../../../components/FadeInUp';

const HomeEcommerceSection = () => {
    return (
        <section className="home-ecommerce">
            <div className="container">
                <div className="home-ecommerce-wrapper">
                    <FadeInUp className="home-ecommerce-left">
                        <h2 className="title">Unlocking Ecommerce Success: Our Journey and Expertise</h2>
                        <p className="text-secondary">
                            Whether you need inventory management systems, customer relationship management tools,
                            or multi-channel selling capabilities, we have the expertise to tailor your Shopify
                            store to your specific needs.
                        </p>
                        <button type="button" className="btn btn-primary-outline">
                            <img src="/images/arrow-right-black.svg" width={13} height={12} alt=""/>
                            About Us
                        </button>
                    </FadeInUp>
                    <FadeInUp className="home-ecommerce-img">
                        <img src="/images/home-ecommerce-img.png" width={570} height={400} alt=""/>
                    </FadeInUp>
                </div>
            </div>
        </section>
    );
};

export default HomeEcommerceSection;